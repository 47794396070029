(function ($) {
  $(".services-slider__carousel").slick({
    variableWidth: true
  });
  $(".gallery-slider__carousel").slick({
    variableWidth: true,
    slidesToShow: 3
  });
  $(".text-image-slider").slick({
    fade: true
  });

  // $(".fancybox").fancybox({
  //     padding: 0,
  // });

  const open_menu = document.getElementById("open-menu");
  const close_menu = document.getElementById("close-menu");
  if (open_menu) {
    open_menu.addEventListener("click", () => {
      document.querySelector(".header-overlay").classList.add("open");
    });
    close_menu.addEventListener("click", () => {
      document.querySelector(".header-overlay").classList.remove("open");
    });
  }
  setTimeout(function () {
    if ($(".rellax").size() > 0) {
      var rellax = new Rellax(".rellax");
    }
  }, 100);
  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);
const checkValue = el => {
  if (el.value != "") {
    el.closest(".field-wrap").classList.add("has-value");
  } else {
    el.closest(".field-wrap").classList.remove("has-value");
  }
};
jQuery(document).on("nfFormReady", function () {
  const formInputs = document.querySelectorAll(".ninja-forms-field");
  if (formInputs) {
    for (let f = 0; f < formInputs.length; f++) {
      checkValue(formInputs[f]);
      formInputs[f].addEventListener("change", () => {
        checkValue(formInputs[f]);
      });
    }
  }
  const formWrap = document.querySelectorAll(".ninja-forms-form-wrap");
  if (formWrap) {
    for (let fw = 0; fw < formWrap.length; fw++) {
      const wrapTextarea = formWrap[fw].querySelectorAll("textarea");
      if (wrapTextarea) {
        for (let t = 0; t < wrapTextarea.length; t++) {
          wrapTextarea[t].setAttribute("rows", 2);
        }
      }
    }
  }
});
const locationTabs = document.querySelectorAll(".location-tab");
const locationMaps = document.querySelectorAll(".location-map");
if (locationTabs) {
  for (let l = 0; l < locationTabs.length; l++) {
    locationTabs[l].addEventListener("click", () => {
      for (let lt = 0; lt < locationTabs.length; lt++) {
        locationTabs[lt].classList.remove("active");
      }
      locationTabs[l].classList.add("active");
      const dataMap = locationTabs[l].dataset.map;
      for (let m = 0; m < locationMaps.length; m++) {
        locationMaps[m].classList.remove("active");
      }
      document.getElementById(dataMap).classList.add("active");
    });
  }
}