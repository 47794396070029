const headerLogo = document.querySelector(".header__logo img");
const logoSrc = headerLogo.getAttribute("src");
const logoData = headerLogo.getAttribute("data-alt");
const headerEl = document.getElementById("header");
function scrollDown() {
  const body = document.querySelector("body");
  const win_width = window.innerWidth;
  const top = window.scrollY;
  //Header animation

  if (top > 80) {
    headerEl.classList.add("scroll");
    headerLogo.setAttribute("src", logoData);
  } else {
    headerEl.classList.remove("scroll");
    headerLogo.setAttribute("src", logoSrc);
  }

  //Scroll animations
  var animatedEl = document.getElementsByClassName("animate-fade");
  var h = window.innerHeight;
  var i;
  for (i = 0; i < animatedEl.length; i++) {
    var viewportOffset = animatedEl[i].getBoundingClientRect();
    var topPos = viewportOffset.top;
    if (topPos <= h * 0.8) {
      animatedEl[i].classList.add("fade-in");
    }
  }

  // Lazy Load
  var lazy_image = document.querySelectorAll("[data-src]");
  if (lazy_image) {
    //console.log(lazy_image);
    for (var l = 0; l < lazy_image.length; l++) {
      var data_src = lazy_image[l].dataset.src;
      var viewportOffset = lazy_image[l].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h * 1.3) {
        lazy_image[l].setAttribute("src", data_src);
        lazy_image[l].removeAttribute("data-src");
        lazy_image[l].classList.add("loaded");
      }
    }
  }

  // Lazy Background
  var lazy_bg = document.querySelectorAll("[data-bg]");
  if (lazy_bg) {
    //console.log(lazy_bg);
    for (var l = 0; l < lazy_bg.length; l++) {
      var data_src = lazy_bg[l].dataset.bg;
      var viewportOffset = lazy_bg[l].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h * 1.3) {
        lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
        lazy_bg[l].removeAttribute("data-bg");
        lazy_bg[l].classList.add("loaded");
      }
    }
  }
}