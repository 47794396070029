const productMarkup = data => {
  const {
    name,
    countries
  } = data;
  let markup = `
        <div class="product-card flex-layout justify-between">
          <h3>${name}</h3>
          <div class="product-card__countries">`;
  for (let index in countries) {
    country = countries[index];
    markup += `<span>${country.name}</span>, `;
  }
  markup += `</div>
        </div>
  `;
  return markup;
};
const jobMarkup = data => {
  const {
    name,
    city,
    permalink
  } = data;
  let markup = `
        <div class="job-single-card flex-layout justify-between align-items-center">
          <div class="job-single-card__title">
            <h3>${name}</h3>
            <h4>${city}</h4>
          </div>
          <a href="${permalink}" class="job-single-card__link">Learn more</a>
        </div>
  `;
  return markup;
};
const clientMarkup = data => {
  const {
    image,
    alt_text,
    name,
    excerpt
  } = data;
  let markup = `
      <div class="client-item">
        <figure class="client-item__image">
          <img src="${image}" alt="${alt_text}">
        </figure>
        <h3>${name}</h3>
        <h4>${excerpt}</h4>
      </div>
  `;
  return markup;
};
const orderData = (data, order) => {
  if (order === "ASC" || order === undefined) {
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
  if (order === "DESC") {
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    });
  }
  if (order === "Country") {
    data.sort(function (a, b) {
      if (a.countries[0].name < b.countries[0].name) {
        return -1;
      }
      if (a.countries[0].name > b.countries[0].name) {
        return 1;
      }
      return 0;
    });
  }
};
function loadProducts(cat_name, order, posts, page, more = false) {
  var data_posts = posts != null ? posts : null;
  var data_cat_name = cat_name != null ? cat_name : null;
  var data_page = page != null ? page : null;
  jQuery.ajax({
    url: dack_vars.ajaxurl,
    type: "post",
    data: {
      action: "dack_ajax_getProducts",
      posts: data_posts,
      cat_name: data_cat_name,
      page: data_page,
      taxonomy: "product-category",
      post_type: "product"
    },
    dataType: "json",
    beforeSend: function () {
      if (more == false) {
        jQuery("#products-container").empty();
      }
      jQuery(".products__loading").show();
    },
    success: function (data) {
      console.log("data:", data);
      const products_container = document.getElementById("products-container");
      if (data && data.length > 0) {
        orderData(data, order);
        data.forEach(key => {
          products_container.innerHTML += productMarkup(key);
        });
      } else {
        products_container.innerHTML = `<p class="text-center">No products to show</p>`;
      }
      jQuery(".products__loading").hide();
    },
    error: function () {
      jQuery(".products__loading").html("There was an error loading the posts");
    }
  });
}
function loadJobs(cat_name, order, posts, page, more = false) {
  console.log("hola");
  var data_posts = posts != null ? posts : null;
  var data_cat_name = cat_name != null ? cat_name : null;
  var data_page = page != null ? page : null;
  jQuery.ajax({
    url: dack_vars.ajaxurl,
    type: "post",
    data: {
      action: "dack_ajax_getJobs",
      posts: data_posts,
      cat_name: data_cat_name,
      page: data_page,
      taxonomy: "job-category",
      post_type: "job"
    },
    dataType: "json",
    beforeSend: function () {
      console.log("loading");
      if (more == false) {
        jQuery("#products-container").empty();
      }
      jQuery(".products__loading").show();
    },
    success: function (data) {
      console.log("data:", data);
      const products_container = document.getElementById("products-container");
      if (data && data.length > 0) {
        orderData(data, order);
        data.forEach(key => {
          products_container.innerHTML += jobMarkup(key);
        });
      } else {
        products_container.innerHTML = `<p class="text-center">No products to show</p>`;
      }
      jQuery(".products__loading").hide();
    },
    error: function () {
      jQuery(".products__loading").html("There was an error loading the posts");
    }
  });
}
function loadClients(order, posts, page, more = false) {
  var data_posts = posts != null ? posts : null;
  var data_page = page != null ? page : null;
  jQuery.ajax({
    url: dack_vars.ajaxurl,
    type: "post",
    data: {
      action: "dack_ajax_getClients",
      posts: data_posts,
      page: data_page,
      post_type: "client"
    },
    dataType: "json",
    beforeSend: function () {
      console.log("loading");
      if (more == false) {
        jQuery("#clients-container").empty();
      }
      jQuery(".clients-grid__loading").show();
    },
    success: function (data) {
      console.log("data:", data);
      const products_container = document.getElementById("clients-container");
      if (data && data.length > 0) {
        orderData(data, order);
        data.forEach(key => {
          products_container.innerHTML += clientMarkup(key);
        });
      } else {
        products_container.innerHTML = `<p class="text-center">No Clients to show</p>`;
      }
      jQuery(".clients-grid__loading").hide();
    },
    error: function () {
      jQuery(".clients-grid__loading").html("There was an error loading the posts");
    }
  });
}
const productFilters = document.querySelectorAll(".products .archive-filter a");
if (productFilters) {
  for (let i = 0; i < productFilters.length; i++) {
    const filterOrder = productFilters[i].dataset.order;
    const filterCategory = productFilters[i].dataset.category;
    productFilters[i].addEventListener("click", () => {
      loadProducts(filterCategory, filterOrder);
      for (let p = 0; p < productFilters.length; p++) {
        productFilters[p].classList.remove("active");
      }
      productFilters[i].classList.add("active");
    });
  }
}
const jobFilters = document.querySelectorAll(".jobs .archive-filter a");
if (jobFilters) {
  for (let i = 0; i < jobFilters.length; i++) {
    const filterOrder = jobFilters[i].dataset.order;
    const filterCategory = jobFilters[i].dataset.category;
    jobFilters[i].addEventListener("click", () => {
      loadJobs(filterCategory, filterOrder);
      for (let p = 0; p < jobFilters.length; p++) {
        jobFilters[p].classList.remove("active");
      }
      jobFilters[i].classList.add("active");
    });
  }
}
const clientsContainer = document.getElementById("clients-container");
if (clientsContainer) {
  loadClients();
}
const clientFilters = document.querySelectorAll(".clients-grid .archive-filter a");
if (clientFilters) {
  for (let i = 0; i < clientFilters.length; i++) {
    const filterOrder = clientFilters[i].dataset.order;
    clientFilters[i].addEventListener("click", () => {
      console.log("hkhk");
      loadClients(filterOrder);
      for (let p = 0; p < clientFilters.length; p++) {
        clientFilters[p].classList.remove("active");
      }
      clientFilters[i].classList.add("active");
    });
  }
}