let GoogleMapsMarkers = [];
const mapStyles = [{
  "featureType": "all",
  "elementType": "geometry.stroke",
  "stylers": [{
    "color": "#c5b08f"
  }]
}, {
  "featureType": "all",
  "elementType": "labels.text",
  "stylers": [{
    "color": "#005b4b"
  }]
}, {
  "featureType": "all",
  "elementType": "labels.text.stroke",
  "stylers": [{
    "weight": "0.38"
  }]
}, {
  "featureType": "landscape",
  "elementType": "all",
  "stylers": [{
    "color": "#fdf9f3"
  }]
}, {
  "featureType": "road",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#dbceb5"
  }]
}, {
  "featureType": "water",
  "elementType": "all",
  "stylers": [{
    "color": "#f1e7d4"
  }]
}];
const tabsWrapper = document.querySelector('.g-map__countries-inner');
const detailWrapper = document.querySelector('.g-map__country-detail');
const tabsHead = document.querySelector('.g-map__dropdown-head');
const map_loader = document.getElementById('map-loader');
const dropdownList = document.getElementById('dropdown-list');
const countryTabMarkup = data => {
  const {
    name,
    product_categories,
    taxonomy,
    slug
  } = data;
  const categoriesLength = product_categories.length;
  let markup = `
          <div class="map-tab country-tab" id="${taxonomy}-${slug}">
            <h3>${name}</h3>
            <div class="map-tab__categories">`;
  for (let index in product_categories) {
    category = product_categories[index];
    markup += `<span>${category.name}</span>${product_categories.indexOf(product_categories[index]) == categoriesLength - 1 ? '.' : ','} `;
  }
  markup += `</div>
          <span class="map-tab__link">View all products</span>
          </div>
    `;
  return markup;
};
const productTabMarkup = data => {
  const {
    name,
    products,
    slug,
    taxonomy
  } = data;
  const categoriesLength = products.length;
  let markup = `
          <div class="map-tab product-tab" id="${taxonomy}-${slug}">
            <h3>${name}</h3>
            <div class="map-tab__categories">`;
  for (let index in products) {
    product = products[index];
    markup += `<span>${product.name}</span>${products.indexOf(products[index]) == categoriesLength - 1 ? '.' : ','} `;
  }
  markup += `</div>
          <span class="map-tab__link">View all Locations</span>
          </div>
    `;
  return markup;
};
const countryDetailMarkup = data => {
  const {
    name,
    product_categories
  } = data;
  let markup = `
    <div class="country-detail">
      <div class="country-detail__inner">
        <a class="country-detail__back">Back to list</a>
        <div class="country-detail__title">${name}</div>
        <div class="country-detail__list">`;
  for (let index in product_categories) {
    category = product_categories[index];
    const {
      name,
      products
    } = category;
    markup += `
            <div class="country-category">
              <h4>${name}</h4>
              <ul>`;
    for (let index in products) {
      product = products[index];
      markup += `
                <li>${product.name}</li>
              `;
    }
    markup += `</ul></div>
          `;
  }
  markup += `</div>
    </div></div>
  `;
  return markup;
};
const productDetailMarkup = data => {
  const {
    name,
    products
  } = data;
  let markup = `
    <div class="country-detail">
      <div class="country-detail__inner">
        <a class="country-detail__back">Back to list</a>
        <div class="country-detail__title">${name}</div>
        <div class="country-detail__list">`;
  for (let index in products) {
    product = products[index];
    const {
      name,
      countries
    } = product;
    const countriesLength = countries.length;
    markup += `
            <div class="country-category">
              <h4>${name}</h4>
              <ul>`;
    for (let index in countries) {
      country = countries[index];
      markup += `<span>${country.name}</span>${countries.indexOf(countries[index]) == countriesLength - 1 ? '.' : ','} `;
    }
    markup += `</ul></div>
          `;
  }
  markup += `</div>
    </div></div>
  `;
  return markup;
};
const dropdownListMarkup = data => {
  const {
    name,
    slug,
    taxonomy
  } = data;
  const markup = `<li data-card="${taxonomy}-${slug}">${name}</li>`;
  return markup;
};
const tabAction = (id, marker, key) => {
  const countryTab = document.querySelectorAll('.country-tab');
  countryTab[id].addEventListener('click', function () {
    highLightMarker(id, marker, key.pin, key.hover_pin);
    for (let tab = 0; tab < countryTab.length; tab++) {
      countryTab[tab].classList.remove('active');
    }
    countryTab[id].classList.add('active');
  });
  countryTab[id].querySelector('.map-tab__link').addEventListener('click', () => {
    loadCountryDetail(key, backToCountries);
  });
};
const productTabAction = data => {
  const productTab = document.querySelectorAll('.product-tab');
  console.log(data);
  for (let p = 0; p < productTab.length; p++) {
    productTab[p].addEventListener('click', () => {
      console.log('tab data', data[p]);
      for (let tab = 0; tab < productTab.length; tab++) {
        productTab[tab].classList.remove('active');
      }
      productTab[p].classList.add('active');
      const countriesInProducts = data[p].products.flatMap(product => product.countries).map(country => country.name);
      const pinsInProductCountries = GoogleMapsMarkers.filter(pin => countriesInProducts.includes(pin.title));
      console.log(pinsInProductCountries);
      GoogleMapsMarkers.forEach(marker => {
        marker.setIcon(data[p].pin);
      });
      for (let g = 0; g < pinsInProductCountries.length; g++) {
        GoogleMapsMarkers[pinsInProductCountries[g].id].setIcon({
          url: data[p].hover_pin,
          scaledSize: new google.maps.Size(48, 72)
        });
        MAP.setZoom(2);
      }
    });
    productTab[p].querySelector('.map-tab__link').addEventListener('click', () => {
      loadProductDetail(data[p], backToProducts);
    });
  }
};
const loadCountryDetail = (data, callback) => {
  tabsWrapper.style.display = 'none';
  detailWrapper.style.display = 'block';
  detailWrapper.innerHTML = countryDetailMarkup(data);
  tabsHead.innerHTML = data.name;
  const map_dropdown_items = map_dropdown.querySelectorAll('.g-map__dropdown-list li');
  for (let index = 0; index < map_dropdown_items.length; index++) {
    map_dropdown_items[index].classList.remove('active');
    if (map_dropdown_items[index].innerHTML === data.name) {
      map_dropdown_items[index].classList.add('active');
    }
  }
  callback(data);
};
const loadProductDetail = (data, callback) => {
  tabsWrapper.style.display = 'none';
  detailWrapper.style.display = 'block';
  detailWrapper.innerHTML = productDetailMarkup(data);
  tabsHead.innerHTML = data.name;
  const map_dropdown_items = map_dropdown.querySelectorAll('.g-map__dropdown-list li');
  for (let index = 0; index < map_dropdown_items.length; index++) {
    map_dropdown_items[index].classList.remove('active');
    if (map_dropdown_items[index].innerHTML === data.name) {
      map_dropdown_items[index].classList.add('active');
    }
  }
  callback(data);
};
const backToCountries = data => {
  document.querySelector('.country-detail__back').addEventListener('click', function () {
    tabsWrapper.style.display = 'block';
    detailWrapper.style.display = 'none';
    detailWrapper.innerHTML = '';
    GoogleMapsMarkers.forEach(marker => {
      marker.setIcon(data.pin);
    });
    MAP.setZoom(2);
    tabsHead.innerHTML = 'Select a country';
    const countryTab = document.querySelectorAll('.country-tab');
    for (let tab = 0; tab < countryTab.length; tab++) {
      countryTab[tab].classList.remove('active');
    }
    const map_dropdown_items = map_dropdown.querySelectorAll('.g-map__dropdown-list li');
    for (let index = 0; index < map_dropdown_items.length; index++) {
      map_dropdown_items[index].classList.remove('active');
    }
  });
};
const backToProducts = data => {
  document.querySelector('.country-detail__back').addEventListener('click', function () {
    tabsWrapper.style.display = 'block';
    detailWrapper.style.display = 'none';
    detailWrapper.innerHTML = '';
    GoogleMapsMarkers.forEach(marker => {
      marker.setIcon(data.pin);
    });
    MAP.setZoom(2);
    tabsHead.innerHTML = 'Select a product';
    const map_dropdown_items = map_dropdown.querySelectorAll('.g-map__dropdown-list li');
    for (let index = 0; index < map_dropdown_items.length; index++) {
      map_dropdown_items[index].classList.remove('active');
    }
  });
};
const countryDetailAction = key => {
  loadCountryDetail(key, backToCountries);
};
const loadCountryTabs = (data, wrapper) => {
  data.forEach(key => {
    wrapper.innerHTML += countryTabMarkup(key);
    //countryDetailAction(key)
  });
};

const loadCountryList = (data, wrapper, allText) => {
  wrapper.innerHTML = '';
  wrapper.innerHTML += `<li data-card="all" class="active">${allText}</li>`;
  data.forEach(key => {
    wrapper.innerHTML += dropdownListMarkup(key);
  });
};
const loadProductTabs = (data, wrapper) => {
  product_id = 0;
  data.forEach(key => {
    wrapper.innerHTML += productTabMarkup(key);
    product_id++;
  });
};
function loadCountries() {
  GoogleMapsMarkers = [];
  map_loader.classList.add('active');
  jQuery.ajax({
    url: dack_vars.ajaxurl,
    type: "post",
    data: {
      action: "dack_ajax_getCountries"
    },
    dataType: "json",
    beforeSend: function () {},
    success: function (data) {
      console.log("data:", data);
      tabsWrapper.innerHTML = '';
      loadCountryTabs(data, tabsWrapper);
      loadCountryList(data, dropdownList, 'All countries');
      tabsHead.innerHTML = 'Select a country';
      tabsWrapper.style.display = 'block';
      detailWrapper.style.display = 'none';
      LoadGoogleMaps(data);
      map_loader.classList.remove('active');
    },
    error: function () {
      console.log('error');
    }
  });
}
function loadProductCats() {
  map_loader.classList.add('active');
  jQuery.ajax({
    url: dack_vars.ajaxurl,
    type: "post",
    data: {
      action: "dack_ajax_getProductCats"
    },
    dataType: "json",
    beforeSend: function () {},
    success: function (data) {
      console.log("product data:", data);
      tabsWrapper.innerHTML = '';
      loadProductTabs(data, tabsWrapper);
      loadCountryList(data, dropdownList, 'All products');
      tabsHead.innerHTML = 'Select a product';
      productTabAction(data);
      tabsWrapper.style.display = 'block';
      detailWrapper.style.display = 'none';
      GoogleMapsMarkers.forEach(marker => {
        marker.setIcon(data[0].pin);
      });
      MAP.setZoom(2);
      map_loader.classList.remove('active');
    },
    error: function () {
      console.log('error');
    }
  });
}
const filterCountryBtn = document.getElementById('filter-country');
const filterProductsBtn = document.getElementById('filter-products');
if (filterProductsBtn) {
  filterProductsBtn.addEventListener('click', () => {
    loadProductCats();
    filterCountryBtn.classList.remove('active');
    filterProductsBtn.classList.add('active');
  });
}
if (filterCountryBtn) {
  filterCountryBtn.addEventListener('click', () => {
    loadCountries();
    filterCountryBtn.classList.add('active');
    filterProductsBtn.classList.remove('active');
  });
}
const highLightMarker = (id, marker, icon, hover_icon) => {
  GoogleMapsMarkers.forEach(marker => {
    marker.setIcon(icon);
  });
  GoogleMapsMarkers[id].setIcon({
    url: hover_icon,
    scaledSize: new google.maps.Size(48, 72)
  });
  const latLng = marker.getPosition();
  MAP.setCenter(latLng);
  MAP.setZoom(4);
};
const GOOGLE_MAPS_WRAPPER = document.getElementById('products-map');
if (GOOGLE_MAPS_WRAPPER) {
  loadCountries();
}

//Load Google Maps
function LoadGoogleMaps(pins) {
  const InitializeMap = (lat, lng, zoom = 2) => {
    //SET POSITION OF MAP
    let PinPosition = new google.maps.LatLng(0, 0); //set longitude and latitude

    // option for google map object
    let myOptions = {
      zoom: zoom,
      center: PinPosition,
      mapTypeId: "roadmap",
      mapTypeControl: false,
      styles: mapStyles,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      backgroundColor: '#ffffff',
      panControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    };

    // create new map make sure a DIV with id myMap exist on page
    MAP = new google.maps.Map(GOOGLE_MAPS_WRAPPER, myOptions); //generate map into div id myMap

    let pin_id = 0;
    pins.forEach(key => {
      //console.log('key' + pin_id + '', key);
      let marker = loadMarker(pin_id, key, key.pin, key.hover_pin, MAP);
      GoogleMapsMarkers.push(marker);
      tabAction(pin_id, marker, key);
      pin_id++;
    });
    return MAP;
  };
  InitializeMap(0, 0);
}
function loadMarker(id, key, icon, iconHover, map_selector) {
  let {
    coords
  } = key;
  let {
    lat,
    long
  } = coords;
  const map_pin = icon;
  //console.log(map_pin);

  // create new marker
  let marker = new google.maps.Marker({
    id: id,
    map: map_selector,
    title: key.name,
    icon: map_pin,
    zIndex: 1,
    position: new google.maps.LatLng(lat, long)
  });
  marker.addListener('click', function () {
    let marker_id = this.id;
    //highLightMarker(marker_id, marker, icon, iconHover);
    //loadCountryDetail(key, backToCountries);
  });

  // marker.addListener('mouseover', function () {
  //   marker.setIcon(iconHover);
  // })

  // marker.addListener('mouseout', function () {
  //   marker.setIcon(icon);
  // })

  return marker;
}
const map_dropdown = document.querySelector('.g-map__dropdown');
if (map_dropdown) {
  map_dropdown.querySelector('.g-map__dropdown-head').addEventListener('click', () => {
    map_dropdown.classList.toggle('open');
    const map_dropdown_items = map_dropdown.querySelectorAll('.g-map__dropdown-list li');
    for (let index = 0; index < map_dropdown_items.length; index++) {
      map_dropdown_items[index].addEventListener('click', function () {
        const thisCard = document.getElementById(this.getAttribute('data-card'));
        map_dropdown.classList.remove('open');
        tabsWrapper.style.display = 'block';
        detailWrapper.style.display = 'none';
        const thisText = this.innerHTML;
        for (let l = 0; l < map_dropdown_items.length; l++) {
          map_dropdown_items[l].classList.remove('active');
        }
        this.classList.add('active');
        if (this.getAttribute('data-card') == 'all') {
          const countryTab = document.querySelectorAll('.map-tab');
          for (let tab = 0; tab < countryTab.length; tab++) {
            countryTab[tab].classList.remove('active');
          }
        } else {
          thisCard.click();
          document.querySelector('.g-map__countries').scrollTop = thisCard.offsetTop;
          tabsHead.innerHTML = thisText;
        }
      });
    }
  });
}